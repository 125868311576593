@import "mixin.scss";


*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;

}

html, body {

    width: 100%;
    height: 101%;
}


html{
  font-size: 14px;
  @include phone {
      font-size: 12px!important;
  }
  @include tablet {
      font-size: 14px!important;
  }
  @include laptop {
      font-size: 14px!important;
  }
  @include desktop {
      font-size: 16px!important;
  }

}

body{
    font-family: 'catamaran', sans-serif, arial;
    background-color: #fff;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.wrapper{
    position: relative;
    overflow: hidden;
    min-height: 101%;
}
#wrapper-fixed-height{
  height: 101%;
  min-height: 700px;
  @media screen and(max-width: 991px){
    overflow: hidden;
    height: auto;
    min-height: auto;
  }

}

.container {
    position: relative;
    max-width: 1140px;
    margin: 0 auto;


     @include desktop {
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
    }

    @include laptop {
        width: 90%;
        margin: 0 auto;
    }

    @include tablet {
        width: 90%;
      margin: 0 auto;
    }

    @include phone {
        width: auto;
        margin: 0 auto;

    }
    @extend %clearfix;

}

.header-left{
    float: left;
    width: 50%;
}

ul{
    padding: 0;
    margin: 0;
}

li{
    list-style: none;
}
.empty{
    height: 50px;
}
h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    font-weight: normal;
}

a{
  color: #000;
  cursor: pointer;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: #c0863b;
  }
  &:active{
    box-shadow: none;
    border:none;
    text-decoration: none;
    color: #000;
  }
  &:focus{
    text-decoration: none;
    color: #000;
  }
}
/*__________________________*/

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, a:active, a:focus {
        outline:0px !important;
    }
