


///////////////____________Mixins________________

%clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}



@mixin box-shadow($params) {
    box-shadow: ($params);
    -webkit-box-shadow: ($params);
    -moz-box-shadow: ($params);
}
@mixin transition($params) {
    transition: ($params);
    -webkit-transition: ($params);
    -moz-transition: ($params);
}
@mixin linear-gradient($params) {
    background: ($params);
    -webkit-background: ($params);
    -moz-background: ($params);
}

//responisve__media-queries
@mixin desktop {
    @media screen and (min-width: 1280px) {
        @content;
    }
}
@mixin laptop {
    @media screen and (min-width: 992px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin phone {
    @media screen and (max-width: 550px) {
        @content;
    }
}

//kerning
@mixin kerning{
  letter-spacing: 1px;
}

//responsive
@function rem($px) {
    @return $px / 14px + 0rem;
}
