//MIXINS//
@import "mixin.scss";
//VARIABLES//
@import "variables.scss";

#shop__page-title{
  color: #000;
@include phone {
  margin-top: 150px!important;
  margin-bottom: 20px;
}
}
#shop__page-customize{
  border-top: 1px solid #f2e9dd;
  border-bottom: 1px solid #f2e9dd;
  padding: 10px 0;
  text-align: center;
  @include phone {
    margin-top: 20px!important;
    margin-bottom: 20px!important;
  }
   span{
    color: $gold;
    margin: 0 rem(30px);
    font-size: rem(14px);
    &:last-child{
      margin: 0;
    }
    &:first-child{
      margin: 0;
    }
  }
}
.ls__preshopping{
  @include phone{
    margin-top: 10px;
  }
  margin-top: 50px;

}
.ls__preshopping-filter{
  float: left;
  @media screen and(max-width: 767px){
    margin-right: 0!important;
    width: 100%;
    text-align: center;
    .ls__filter{
      margin: 0;
    }
  }
}
.dropdown-menu > li > a {
  display: block;
  height: 23px;
}

.btn__filter{
  border:none!important;
  border-radius: 0;
  &:active{
    box-shadow: none;
  }
}
.ls__filter{
  display: inline;
  margin-right: 30px;
}
.btn-group.open .dropdown-toggle{
  box-shadow: none;
  &:active{
    box-shadow: none;
  }
}
.ls__filter-label{
  color: #999;
  line-height: 1;
}
.filter-design{
  border:none;
  background: none;
  display: inline-block;
}
.color-label{
  float: left;
}
.clr-box{
  line-height: 20px;
  margin-right: 5px;
  margin-top: 3px;
  float: left;
  display: block;
  width: 12px;
  height: 12px;
  @include box-shadow(1px 1px 5px 0px rgba(0,0,0,.3));
}
#clr-blue{
  background: blue;
}
#clr-red{
  background: red;
}
#clr-white{
  background: white;
}
#clr-pink{
  background: pink;
}
#clr-yellow{
  background: yellow;
}
#clr-black{
  background: black;
}


#gold-offer{
  font-family: 'Playfair Display',sans-serif,arial;
  font-weight: bold;
  font-size: rem(14px);
  float: right;
  padding: rem(15px) rem(40px);
  max-width: rem(350px);
  text-align: center;
  color: #fff;
  background-image: -moz-linear-gradient( 133deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
  background-image: -webkit-linear-gradient( 133deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
  background-image: linear-gradient( 133deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
  span:nth-child(2){
    margin: 0 rem(14px);
  }
  @media screen and(max-width: 767px){
    float: none;
    margin: 20px auto;
  }
}

.ls__shop-list{
margin-top: 50px;
margin-bottom: 50px;

}
.ls__shop-item{
  padding: 0 25px;
  margin-bottom: 30px;
  width: 33%;
  display: inline-block;
  &:hover{
    .img-responsive{

    }
  }
  .img-responsive{
    @include transition(linear 250ms);
    width: 100%;
  }
  .textile{
    bottom: 1%;
  }
  @media screen and(max-width: 767px){
    margin: 0 auto 50px;
    display: block;
  width: 80%;
  }
}
.ls__shop-link{
  margin: 0 auto;
  max-width: rem(180px);
  display: block;
  position: relative;
}





//_________CUSTOMIZATION_________//
.ls__customize-section{
  background: no-repeat center 0;
  background-size: cover;
  height: auto!important;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  bottom:0;
  @media screen and(max-width: 991px){
    position:relative;
  }
  @include phone{
    .item{
      background-position: center 0!important;
      min-height: 160px!important;
    }
  }
  .item{
    height: 100%!important;
    min-height: 800px;
  }
  #carouselSlideshow .carousel-indicators li{
      border-color: #fff;
  }
  #carouselSlideshow .carousel-indicators li.active{
      background-color: #fff;
  }
}
#customize-slide-01{
  background-image: url('../assets/images/shop-items/customization-shirt-pic-01.jpg');
}
#customize-slide-02{
  background-image: url('../assets/images/shop-items/customization-shirt-pic-01.jpg');
}
#customize-slide-03{
  background-image: url('../assets/images/shop-items/customization-shirt-pic-01.jpg');
}


#carouselSlideshow .carousel-indicators{
  @include phone{
    display: block;
    bottom: 0;
  }
}



.settings__options-box {
padding: 0 15px;
margin-bottom: 30px;
@include phone{
  padding: 0 5px;
}
}
.customize__settings-left{
  padding-top: rem(100px);
  width: 72%;
  float: left;
  @media screen and(max-width: 991px){
    margin-top: 120px;
    width: 100%;
  }

}
.customize__settings-right{
  float: right;
  max-width:320px;
  width: 28%;
  padding: rem(40px) rem(18px);
  height: 100%;
  right: -6.1%;
  top:0;
  position: absolute;
  z-index: 12;
  background: rgba(0,0,0, .8);
  @media screen and(max-width: 991px){
    right: 0;
    max-width: 100%;
    width: 100%;
    position: relative;
    background: #000;
  }

  .customize__settings-top{
    h3{
      font-size: rem(24px)!important;
    }
    font-family:'baskerville','Playfair Display',sans-serif,arial;
    font-weight: 400;
    text-align: center;
    line-height: 1.41;
    color: #fff;
    p{
      margin-top: rem(7px);
      font-size: rem(16px);
      font-style: italic;
    }
    #customize-product-price{
      margin-top: rem(5px);
      font-size: rem(24px);
      font-family: 'baskerville','Playfair Display',sans-serif,arial, sans-serif,arial;
      font-weight: 200;
    }
  }
  .customize__settings-font-1{
    color: #707070!important;
    font-size: 14px;

    span{
      margin-left: 5px;
      color: #fff!important;
      text-transform: uppercase;
    }
  }
  .customize__settings-descr{
    max-height: 140px;
    overflow: scroll;
    margin-bottom: rem(20px);
    @include phone{
      max-height: 100px;
    }
    @media screen and(min-width: 1420px){
      max-height: 400px;
    }
    p{
      font-size: rem(14px);
      line-height: 1.2;
      margin-bottom: 10px;
      color: #a7a7a7;
      @media screen and(max-width: 991px){
        text-align: center;
      }
    }
  }
  .customize__settings-btn{
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
     a{
       line-height: 40px;
       height: 40px;
       color: #fff;
       width: 100%;
       display: block;
     }
  }
}

.customize__settings-about{
  max-width: 400px;
margin: 0 auto;
}
.customize__settings-input{
  width: 100%;
  text-align: center;
  .btn-group{
    width: 100%;
    margin-bottom: 20px;
    .btn-settings{
      @include transition(linear 150ms);
          width: 100%;
          border-radius: 0;
          background: rgba(255,255,255, .1);
          color: #cbcbcb;
          text-align: left;
          .caret{
            position: absolute;
            right: 10px;
            top:45%;
            color: $gold;
          }
          &:focus{
            background: rgba(255,255,255, .1);
            color: #fff;
          }
          &:hover{
            background: rgba(255,255,255, .2);
          }
    }
    .dropdown-menu{
      width: 100%;
      border-radius: 0px 0px 5px 5px;
      background-color: rgba(255,255,255, .9);
      li a{
        &:hover{
          background-color: $gold;
          color: #fff;
        }
      }
    }
  }

}



.xs-no-margin{
  @media screen and(max-width: 991px){
    margin: 0!important;
  }
}


.customizer{
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 131px;
  @media screen and(max-width: 991px){
    height: auto;
    position: relative;
    margin-top: 0;
  }

  ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
  }
  /* optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #FF0000;
  }
}

.full-height{
    height: 100%;
  @media screen and(max-width: 991px){
    height: auto;
  }
}
.no-padding{
  @media screen and(max-width: 991px){
    padding: 0!important;
  }
}
.container-full-width-mobile{
  @media screen and(max-width: 991px){
    width: 100%!important;
  }
}

.settings__options-box{
  width: 33.33333333%;
  float: left;
  text-align: center;
  .select__image-box img{
    width: 100%;
    max-width: 200px;
  }
  .man__size-pic{
    height: 192px!important;
    max-width: 135px!important;
  }
  .select__image-box{
    width: 100%;
    height: 120px;
    max-width: 120px;
    margin: 0 auto;
      @include phone{
        height: auto;
        max-width: 100px;
      }
    }

.cuff__pic{
  max-width: 150px;
  height: auto;
}
.single-pocket{
height: 140px;
max-width: 100px;
}
.double-pocket{
max-width: 200px;
height: auto;
@include tablet{
  height: 110px;
}
}
    .man__size-pic{
      @include phone{
        img{
          margin: 0 auto;
          left: 0;
          right: 0;
          position: absolute!important;
          bottom: 22%;
          max-width: 100px;
        }
      }
    }

}

.label-item{
  display: block;
  cursor: pointer;
  img{
    @include transition(ease 150ms);
  }
  &:hover{
    img{
      transform: scale(.9);
    }

  }
}
.radio-box{
  position: relative;
  .select__radio-box{
    color: #AAAAAA;
    display: block;
    background-image:linear-gradient( 140deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
    background-image: -moz-linear-gradient( 140deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
    background-image: -webkit-linear-gradient( 140deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
    background-image: -ms-linear-gradient( 140deg, rgb(150,116,71) 0%, rgb(201,161,110) 100%);
    margin: 0 auto;
    cursor: pointer;

  }

.select__radio-box input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.radio-text{
  display: inline-block;
  @include phone{
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
}
.select__radio-box {

  position: relative;
  text-align: left;
  color: #fff;
  cursor: pointer;
  padding: 10px 40px 7px 12px;
  z-index: 1;
  @include tablet{
    max-width: 95%;
  }
  @include phone{
    padding: 10px 3px 7px;
    height: 80px;
    .radio-text{
      h4{
        text-align: center;
      }
      p{
        text-align: center;
      }
    }
  }
  h4{
    text-align: left;
    display: block;
    font-size: rem(16px);
    font-weight: 400;
    font-family:'baskerville','Playfair Display',sans-serif,arial;
    margin-bottom: 5px;
  }
  p{
    line-height: 1;
    display: block;
    font-size: rem(12px);
    font-weight: 300;

  }
}

.radio-right{
  float: right;
  width: 100%;
}
  .check{
  position: absolute;
  top: 50%;
  right: 15px;
  height: 100px;
  margin-top: -13px;
  border: 1px solid #fff;
  background-color: rgba(0,0,0, .2);
  border-radius: 100%;
  height: 25px;
  width: 25px;
  z-index: 0;
	@include transition(linear 150ms);
  @include phone{
    position: absolute;
    top: 45px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
.select__radio-box{
    &:hover{
      .check{
        border: 4px solid #FFFFFF;
      }
    }
}



.check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 3px;
  left: 3px;
  margin: auto;
  @include transition(linear 150ms);
}

input[type=radio]:checked ~ .check {
  border: 2px solid #fff;
}

input[type=radio]:checked ~ .check::before{
  background: #fff;
}

input[type=radio]:checked ~ label{
  color: #fff;
}

}
.man-size-negative-margin{
  margin-top: -80px!important;
  max-width: 160px!important;
  @include phone{
    margin-top: -54px!important;
  }
}

.customize__note-form{
  text-align: center;
  .customize__note-pic{
    margin-bottom: 25px;
  }
  h2{
    font-size: rem(24px);
    font-family:'baskerville','Playfair Display',sans-serif,arial;
    margin-bottom: 4px;
  }
  p{
    font-size: rem(15px);
    color: #999999;
    margin-bottom: 25px;
  }
  textarea{
    width: 90%;
    max-width: 440px;
    min-width: 280px;
    height: 100px;
    padding: 6px 15px;
    box-sizing: border-box;
    border: 1px solid #000;
  }
  textarea:focus {
    border: 1px solid #999;
}
}

textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {
    border-color: rgba(229, 103, 23, 0.8);
    outline: 0 none;
}



.modal-title{
  text-align: center;
  font-size: rem(20px);
  font-family:'baskerville','Playfair Display',sans-serif,arial;
}
.modal__cart-product{
  border-bottom: 1px solid $gold;
  padding: 30px 15px;
  letter-spacing: .5px;
  @include phone{
    padding: 30px 0;
  }
  .modal__cart-image{
    width: 100%;
    max-width: 70px;
    display: block;
margin: 0 auto;
  }
  h5{
    letter-spacing: 1px;
    font-size: rem(15px);
    color: #000;
    margin-bottom: 12px;
  }
  p{
    font-size: rem(12px);
    font-weight: 300;
    color: #666;
    margin-bottom: 2px;
  }

}
.modal__cart-value{
  color: #000!important;
  margin-left: rem(10px);
  @include phone{margin-left: 5px;}
}
.modal-footer{
  border-top: none;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 35px;
  button {
    letter-spacing: 2px;
    border-radius: 0;
    font-weight: 400;
    height: 42px;
    max-width: 300px;
    padding-top: 10px;
    font-size: rem(12px);
  }
  button, a{

    line-height: 1;
    margin: 0 auto;
    display: block!important;
  }
  a{
    margin-top: 25px;
    color: #666;
    &:hover{
      color: $gold;
    }

  }
}

.modal__cart-delete{
  padding-top: 50px;
  float: right;
  a{

    &:hover{
      i{
        color: $gold;
      }

    }
  }
  i{
    font-size: 15px;
    color: #999;

  }
}
.modal__cart-total{
  padding-top: 20px;
  text-align: right;
  color: #999;
}

.modal-content{
  border-radius: 0;
}

.less-padding{
  padding: 0 5px;
}
.modal-dialog{
  margin-top: 100px;
  @include phone{
    margin-top: 20px;
  }
}
