//MIXINS//
@import "mixin.scss";
//VARIABLES//
@import "variables.scss";
/* CSS Document */


.slide-1{
  background-image:url(../assets/images/banner-1.jpg);
  @include phone{
   background-position: 60% 0!important;
     background-color: rgba(255,255,255, .05);
  }

}
.slide-2 {
  background-image:url(../assets/images/banner-2.jpg);
  @include phone{
    background-position: 60% 0!important;
  }
}.slide-3{
  background-image:url(../assets/images/banner-3.jpg);
  @include phone{
    background-position: 70% 0!important;
  }
}

.swiper__bg{
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: none;
  @include phone{
  display: block;
  }
}
.swiper__bg-white{
  background: rgba(225,225,225,0.7);
}

.swiper__dark-bg{
  background: rgba(0,0,0,0.7);
}




.main-title{
  font-family: 'baskerville', 'libre baskerville', sans-serif,arial!important;
  font-size: rem(67px);
  @include phone{
    padding-top: rem(50px);
  }

  @media screen and (max-width: 964px) {

      font-size: rem(40px);
  }
  @media screen and (max-width: 1140px) {
      padding-top: rem(70px);

  }

  @media screen and (max-width: 768px) {
      padding-top: 0;
      font-size: rem(40px);
  }



  font-family: 'Playfair Display',sans-serif,arial;
  text-transform: uppercase;
  display: block;
  letter-spacing: -1px;
  font-weight: 500;
  span{
    letter-spacing: 1px;
    font-size: rem(24px);
    text-transform: uppercase;
    font-weight: 200;
    font-family: 'catamaran',arial,sans-serif;
    display: block;
  }

  p{
    font-size: rem(16px);
    @include tablet{
      opacity: .2;
    }
  }

}

/// SWIPER

.big__header-swiper{
  margin-top: 130px;
  position: relative;
  @media screen and(max-width: 767px){
    margin-top:115px;
  }
  .swiper-wrapper{

    .swiper-slide{
      background-repeat: no-repeat;
      background-position:center 0;
      padding: 15% 0;
      text-align: left;
      width: 100%;
      @include tablet{
        padding: 5% 0;
      }
      @include laptop{
        padding: 15% 0;
      }
      @include desktop{
        padding: 12% 0 17%;
      }
    }
  }
}

.big__swiper-container{
  width: 100%;
  margin: 0 auto;
  max-resolution: 1140px;
  padding: 0 50px;
}

.swiper-pagination{
  bottom:20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  .swiper-pagination-bullet{
    margin: 0 5px;
  }
}

.carousel-btn{
  @include phone{
    width: auto!important;
  }

}
