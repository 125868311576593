#customize-slide-04 {
	background-image: url(../assets/images/banner-4.jpg);
}

.inner__page-header{
	margin-top: 130px;
	@media screen and(max-width: 767px){
		margin-top: 115px;
	}
}
.content__item {
	text-align: center;
	color: #fff;
	padding: 80px 0 0 0;
}
.content__item h2 {
	font-size: 60px;
	margin-bottom: 25px;
	font-family: 'libre baskerville',arial,sans-serif;
}
.content__item h2 span {
	text-transform: uppercase;
}
.content__item p {
	display: block;
	width: 100%;
	max-width: 490px;
	margin: 0 auto;
	text-align: left;
	font-size: 14px;
	color: #ffffff;
	padding-top: 25px;
	font-family: 'catamaran',arial,sans-serif;
	text-align: center;
}
.slider__info_block {
	width: 100%;
	max-width: 800px;
	box-sizing: border-box;
	padding: 40px 50px;
	font-size: 14px;
	color: #bc9665;
	border: 1px solid #cba675;
	margin: -130px auto 60px;
	background: #fff;
	position: relative;
	z-index: 99;
	font-family: 'catamaran',arial,sans-serif;
}
.inside__page h2 {
	font-size: 24px;
	text-align: center;
	text-transform: uppercase;
	color: #000;
    margin-bottom: 80px;
    font-family: 'libre baskerville',arial,sans-serif;
    line-height: 1.7;
}
.quality__block {
	text-align: center;
}
.quality__block h4 {
	text-transform: uppercase;
	color: #000;
	margin: 15px 0 5px 0;
	font-family: 'libre baskerville',arial,sans-serif;
	font-size: 12px;
}
.quality__img {
	height: 70px;
	display: table-cell;
	vertical-align: middle;
	width: 1000px;
}
.quality__block span {
	display: block;
	text-transform: uppercase;
	font-size: 12px;
	color: #8e732a;
	font-family: 'catamaran',arial,sans-serif;
}
.wearing__block {
	padding: 65px 0 0 0;
	max-width: 1000px;
	margin: 0 auto;
}
.wearing__block .wearing__span {
	display: block;
	text-align: center;
	font-size: 18px;
	color: #000000;
	font-family: 'libre baskerville',arial,sans-serif;
	font-style: italic;
}
.clear {
	clear: both;
}
.copy__center {
	display: block;
	margin: 20px auto;
}
.author__name {
	display: block;
	text-align: center;
	font-size: 14px;
	color: #000000;
	font-family: 'catamaran',arial,sans-serif;
}
.slider__info_block p {
	margin-bottom: 25px;
}
.slider__info_block p:last-child {
	margin-bottom: 0;
}
.body__block h3 {
	font-size: 24px;
	text-transform: uppercase;
	font-family: 'libre baskerville',arial,sans-serif;
	color: #000000;
	margin-bottom: 15px;
}
.body__block p {
	display: block;
	min-height: 140px;
	margin-bottom: 10px;
}
.body__block img {
	display: block;
	margin: 0 auto;
}
.body__wearing-block {
	background: #fff;
	border: 1px solid #cba675;
	border-bottom: 0;
	margin: 0 15px;
	text-align: center;
	padding: 40px 0;
}
.body__catalogue {
    padding: 90px 0 0 0;
    background: url(../assets/images/texture.jpg);
}
.body__wearing-block h3 {
	font-family: 'libre baskerville',arial,sans-serif;
	font-size: 24px;
	color: #000000;
	margin-bottom: 20px;
	font-style: italic;
}
.body__wearing-block p {
	font-size: 16px;
	color: #999999;
	font-family: 'catamaran',arial,sans-serif;
	display: block;
	margin: 0 auto;
	max-width: 680px;
}
.body__wearing-block img {
	vertical-align: top;
}
.contact__form h3 {
	text-align: center;
	font-size: 18px;
	color: #000000;
	font-family: 'libre baskerville',arial,sans-serif;
	margin-bottom: 50px;
}
.contact__form {
	overflow: hidden;
}
.inside__block .left__block,
.inside__block .right__block {
	float: left;
	width: 50%;
}
.inside__block .left__block p {
	font-size: 14px;
	color: #a4865f;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 300;
	margin-bottom: 20px;
	display: block;
	max-width: 260px;
}
.left__block .left__inside {
	padding-bottom: 80px;
}
.inside__block .left__block h4 {
	font-size: 18px;
	color: #000000;
	font-family: 'libre baskerville',arial,sans-serif;
	text-transform: uppercase;
	margin-bottom: 20px;
}
.inside__block .left__block ul li {
	margin-bottom: 15px;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 500;
	max-width: 260px;
}
.inside__block .left__block ul li .cont_title {
	display: block;
	font-size: 14px;
	color: #999999;
}
.inside__block .left__block ul li .cont_des {
	font-size: 14px;
	color: #000000;
	font-weight: 400;
}
.right__block label {
	display: block;
	position: relative;
	margin-bottom: 30px;

}
.right__block label input[type="text"] {
	display: block;
	width: 100%;
	border: 1px solid #999999;
	border-bottom: 1px solid #b4b4b4;
	height: 36px;
	padding-left: 15px;

}
.right__block label span {
	display: inline-block;
	background: #fff;
	padding: 5px;
	line-height: 1;
	font-size: 14px;
	color: #000000;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 400;
	position: absolute;
	left: 10px;
	top: -10px;
		z-index: 100;
}
.right__block label textarea {
	display: block;
	width: 100%;
	border: 1px solid #999999;
	border-bottom: 1px solid #b4b4b4;
	height: 145px;
		z-index: 1;
		padding: 20px 15px;
}
.right__block .form__button {
	display: block;
	text-align: center;
	width: 100%;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 500;
	color: #fff;
	text-transform: uppercase;
	border: none;
	background: #015bff;
	line-height: 1;
	padding: 17px 0 10px;
}
.address__block {
	text-align: center;
	padding-bottom: 45px;
}
.address__block h4 {
	font-size: 18px;
	color: #000000;
	text-transform: uppercase;
	font-family: 'libre baskerville',arial,sans-serif;
	margin-bottom: 15px;
}
.address__block span {
	display: block;
	font-size: 14px;
	color: #999999;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 500;
}
.address__block p {
	font-size: 14px;
	color: #000000;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 500;
	padding-bottom: 15px;
}
.history__box {
	margin-top: 130px;
	min-height: 850px;
	background: #151412 url(../assets/images/banner-5.jpg) no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-size: cover;
	position: relative;
	@media screen and(max-width: 767px){
		margin-top: 115px;
	}
}
.inside__history {
	padding: 70px 0 130px;
	text-align: center;
}
.inside__history h1 {
	font-size: 30px;
	color: #ffffff;
	line-height: 1;
	font-family: 'baskerville',arial,sans-serif;
	text-transform: uppercase;
	margin-bottom: 15px;
}
.what__keeps {

	font-size: 24px;
	color: #fff;
	font-family: 'baskerville',arial,sans-serif;
	font-style: italic;
	font-weight: 300;
	margin-bottom: 15px;
}
.copyright__by {
	font-size: 14px;
	color: #ffffff;
	font-family: 'catamaran',arial,sans-serif;
	text-shadow: 0px 0px 10px rgba(150, 150, 150, 1)
}
.tagline {
	color: #fff;
	max-width: 760px;
	box-sizing: border-box;
	margin: 130px auto 0;
	padding: 30px 30px 0;
	position: relative;
}
.tagline p {
	font-family: 'baskerville',arial,sans-serif;
	font-size: 24px;
	padding-bottom: 15px;
	margin-bottom: 15px;
	position: relative;
}
.tagline p:first-child:before {
	content: "";
	display: block;
	width: 50px;
	height: 1px;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -25px;
	background: #8a8064;
}
.tagline:before {
	content: " ";
	display: block;
	width: 29px;
	height: 25px;
	background: url(../assets/images/kov-1.png) no-repeat;
	position: absolute;
	top: 0;
	left: 0;
}
.tagline:after {
	content: " ";
	display: block;
	width: 29px;
	height: 25px;
	background: url(../assets/images/kov-2.png) no-repeat;
	position: absolute;
	bottom: 15px;
	right: 0;
}
.history__title {
	text-align: center;
	font-size: 42px;
	color: #fff;
	font-family: 'baskerville',arial,sans-serif;
	font-weight: 300;
	margin-bottom: 35px;
}
.history__title span {
	font-weight: 700;
}
.image__center {
	display: block;
	margin: 0 auto;
}
.images-1 {
	width: 100%;
	max-width: 908px;
}
.images-2 {
	width: 100%;
	max-width: 556px;
}
.images-3 {
	width: 100%;
	max-width: 385px;
	display: block;
	margin: 0 auto;
}
.images-4 {
	width: 100%;
	max-width: 607px;
	display: block;
	margin: 0 auto;
}
.images-5 {
	width: 100%;
	max-width: 220px;
	display: block;
	margin: 0 auto;
}
.images-6 {
	width: 100%;
	max-width: 154px;
	display: block;
	margin: 0 auto;
}
.inside__cd {
	border: 1px solid #ffffff;
	padding: 20px 30px 35px;
	background: rgba(0,0,0, .5);
	color: #fff;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 300;
	letter-spacing: 1px;
}
.inside__cd span {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
}
.inside__cd p {
	margin-bottom: 5px;
}
.same__height .inside__cd {
	min-height: 240px;
}
.same__height {
	padding: 20px 0 90px;
}
.best__pool {
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
	color: #fff;
	padding-bottom: 40px;
}
.best__pool span {
	display: block;
	padding-top: 10px;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 300;
}
.foundation__block {
	overflow: hidden;
}
.foundation__block .images__sardar {
	float: left;
	width: 35%;
}
.foundation__block .text__sardar {
	float: left;
	width: 65%;
	padding-top: 30px;
}
.text__sardar p {
	font-family: 'catamaran',arial,sans-serif;
	font-size: 16px;
	color: #fff;
	display: block;
	margin-bottom: 20px;
}
.text__sardar img {
	margin: 50px auto;
	display: block;
}
.spanish__box h3 {
	text-align: left;
}
.spanish__box {
	padding: 80px 0 45px;
}
.spanish__box .story__box {
	overflow: hidden;
}
.spanish__box .story__box .story__image {
	float: left;
	width: 55%;
}
.spanish__box .story__box .story__text {
	float: left;
	width: 45%;
	color: #fff;
}
.spanish__box .story__box .story__text p {
	font-family: 'catamaran',arial,sans-serif;
	font-size: 16px;
	color: #fff;
	display: block;
	padding: 150px 100px 0 0;
}
.muself_block {
	height: 730px;
	background: url(../assets/images/banner-bg.png) no-repeat center;
	padding-top: 200px;
	box-sizing: border-box;
	color: #fff;
	font-family: 'catamaran',arial,sans-serif;
	font-size: 16px;
	margin: 30px 0;
	position: relative;
	z-index: 99;
}
.muself_block h4 {
	text-align: center;
	font-family: 'libre baskerville',arial,sans-serif;
	font-size: 50px;
	margin-bottom: 20px;
}
.p_born {
	display: block;
	text-align: center;
	padding: 10px 0 30px;
	border-top: 1px solid #fff;
	max-width: 720px;
	margin: 0 auto 30px;
	font-family: 'catamaran',arial,sans-serif;
	font-weight: 300;
	font-size: 16px;
	color: #a09182;
}
.ls__page-footer {
	position: relative;
}
.ratio__block {
	padding: 20px 0 70px;
	overflow: hidden;
	color: #fff;
}
.ratio__block p {
	font-size: 16px;
	margin-bottom: 15px;
	font-family: 'libre baskerville',arial,sans-serif;
}
.ratio__block img {
	display: block;
	margin: 20px 0 30px;
}
.header__banner {
	background: #000;
	height: 400px;
	position: relative;
}
.banner__bg-1 {
	background: url(../assets/images/banner-4.jpg) no-repeat;
	background-position: center;
}
.banner__bg-2 {
	background: url(../assets/images/banner-6.jpg) no-repeat;
	background-position: center;
}
.banner__bg-3 {
	background: url(../assets/images/banner-7.jpg) no-repeat;
	background-position: center;
}

.logo-center {
	display: none;
	margin: 20px auto;
}
.logo__history {
	position: fixed;
	left: 50%;
	top: 50%;
	margin: -50px 0 0 -133px;
}



@media only screen and (max-width: 900px) {
	.content__item {
	    padding: 80px 15px 0;
	}
	.content__item h2 {
	    font-size: 40px;
	}
	.tagline {
		margin-top: 0;
	}
	.slider__info_block {
		padding: 40px 40px;
	}
	.logo__history {
		display: none;
	}
	.logo-center {
		display: block;
	}
}
@media only screen and (max-width: 768px) {
	.inside__page h2 {
		font-size: 20px;
		margin-bottom: 45px;
	}
	.slider__info_block {
	    padding: 20px;
	}
	.slider__info_block {
		margin-bottom: 40px;
	}
	.body__block {
		padding-top: 50px;
		text-align: center;
	}
	.quality__block {
		padding-bottom: 40px;
	}
	.wearing__block {
		padding-top: 25px;
	}
	.body__wearing-block {
	    padding: 20px 10px 15px;
	}
	.content__item h2 {
	    font-size: 25px;
	}
	.body__block p {
		min-height: auto;
	}
	.body__wearing-block h3 {
		font-size: 22px;
	}
	.inside__cd {
		margin-bottom: 30px;
	}
	.foundation__block .images__sardar {
		width: 100%;
		float: none;
	}
	.foundation__block .text__sardar {
		width: 100%;
		text-align: center;
	}
	.muself_block {
		padding-top: 120px;
	}
	.p_born {
		margin: 20px 25px;
	}
	.ratio__block {
	    padding: 40px 0;
	}
	.ratio__block img {
		display: block;
		margin:  0 auto;
	}
	.spanish__box h3 {
		text-align: center;
	}
	.spanish__box .story__box .story__image {
		width: 100%;
	}
	.spanish__box .story__box .story__text {
		width: 100%;
		text-align: center;
	}
	.spanish__box .story__box .story__text p {
		padding: 20px 10px;
	}
}
@media only screen and (max-width: 660px) {
	.inside__block .left__block,
	.inside__block .right__block {
		width: 100%;
		float: left;
		text-align: center;
		padding-bottom: 50px;
	}
	.left__block .left__inside {
		padding-bottom: 0;
	}
	.inside__block .left__block p {
		max-width: 100%;
	}
	.inside__block .left__block ul li {
		max-width: 100%;
	}
	.address__block {
		padding-bottom: 10px;
	}
	.contact__form h3 {
		margin-bottom: 20px;
	}
	.ratio__block img {
		width: 100%;
		margin-bottom: 40px;
	}
	.images-5 {
		max-width: 135px;
	}
	.logo-center {
		width: 120px;
	}
}
