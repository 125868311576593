@charset "utf-8";
/* CSS Document */
.size-11 			{ font-size:11px !important; line-height:15px !important; }
.size-12 			{ font-size:12px !important; line-height:16px !important; }
.size-13 			{ font-size:13px !important; line-height:17px !important; }
.size-14 			{ font-size:14px !important; line-height:18px !important; }
.size-15 			{ font-size:15px !important; line-height:19px !important; }
.size-16 			{ font-size:16px !important; line-height:20px !important; }
.size-17 			{ font-size:17px !important; line-height:23px !important; }
.size-18 			{ font-size:18px !important; line-height:24px !important; }
.size-19 			{ font-size:19px !important; line-height:25px !important; }
.size-20 			{ font-size:20px !important; line-height:26px !important; }
.size-25 			{ font-size:25px !important; line-height:30px !important; }
.size-30 			{ font-size:30px !important; line-height:36px !important; }
.size-40 			{ font-size:40px !important; line-height:46px !important; }
.size-50 			{ font-size:50px !important; line-height:56px !important; }
.size-60 			{ font-size:60px !important; line-height:66px !important; }
.size-70 			{ font-size:70px !important; line-height:76px !important; }
.size-80 			{ font-size:80px !important; line-height:86px !important; }
.size-90 			{ font-size:90px !important; line-height:96px !important; }
.size-100 			{ font-size:100px !important; line-height:106px !important; }
.size-150 			{ font-size:150px !important; line-height:166px !important; }
.size-200 			{ font-size:200px !important; line-height:216px !important; }

.weight-100 		{ font-weight:100 !important; 	}
.weight-200 		{ font-weight:200 !important; 	}
.weight-300 		{ font-weight:300 !important; 	}
.weight-400 		{ font-weight:400 !important; 	}
.weight-500 		{ font-weight:500 !important; 	}
.weight-600 		{ font-weight:600 !important; 	}
.weight-700 		{ font-weight:700 !important; 	}
.weight-800 		{ font-weight:800 !important; 	}
.weight-900 		{ font-weight:900 !important; 	}

.line-height-0		{ line-height:0 !important; 	}
.line-height-10		{ line-height:10px !important; 	}
.line-height-20		{ line-height:20px !important; 	}
.line-height-30		{ line-height:30px !important; 	}
.line-height-40		{ line-height:40px !important; 	}
.line-height-50		{ line-height:50px !important; 	}
.line-height-60		{ line-height:60px !important; 	}
.line-height-70		{ line-height:70px !important; 	}
.line-height-80		{ line-height:80px !important; 	}
.line-height-90		{ line-height:90px !important; 	}
.line-height-100	{ line-height:100px !important; }

.font-style-italic 	{ font-style:italic; 			}
.font-style-normal 	{ font-style:normal; 			}

/* UTILITIES */
.padding-0 			{ padding:0 !important; 	}
.padding-5 			{ padding:5px !important; 	}
.padding-10 		{ padding:10px !important; 	}
.padding-15 		{ padding:15px !important; 	}
.padding-20 		{ padding:20px !important; 	}
.padding-30 		{ padding:30px !important; 	}
.padding-40 		{ padding:40px !important; 	}
.padding-50 		{ padding:50px !important; 	}
.padding-60 		{ padding:60px !important; 	}
.padding-70 		{ padding:70px !important; 	}
.padding-80 		{ padding:80px !important; 	}
.padding-90 		{ padding:90px !important; 	}
.padding-100 		{ padding:100px !important; }

.padding-top-0		{ padding-top:0 !important; }
.padding-top-5		{ padding-top:5 !important; }
.padding-top-10		{ padding-top:10px !important; }
.padding-top-15		{ padding-top:15px !important; }
.padding-top-20		{ padding-top:20px !important; }
.padding-top-30		{ padding-top:30px !important; }
.padding-top-40		{ padding-top:40px !important; }
.padding-top-50		{ padding-top:50px !important; }
.padding-top-60		{ padding-top:60px !important; }
.padding-top-80		{ padding-top:80px !important; }
.padding-top-100	{ padding-top:100px !important; }
.padding-top-130	{ padding-top:130px !important; }
.padding-top-150	{ padding-top:150px !important; }
.padding-top-180	{ padding-top:180px !important; }
.padding-top-200	{ padding-top:200px !important; }

.padding-right-0	{ padding-right:0 !important; }
.padding-right-5	{ padding-right:5 !important; }
.padding-right-10	{ padding-right:10px !important; }
.padding-right-15	{ padding-right:15px !important; }
.padding-right-20	{ padding-right:20px !important; }
.padding-right-30	{ padding-right:30px !important; }
.padding-right-40	{ padding-right:40px !important; }
.padding-right-50	{ padding-right:50px !important; }
.padding-right-60	{ padding-right:60px !important; }
.padding-right-80	{ padding-right:80px !important; }
.padding-right-100	{ padding-right:100px !important; }
.padding-right-130	{ padding-right:130px !important; }
.padding-right-150	{ padding-right:150px !important; }
.padding-right-180	{ padding-right:180px !important; }
.padding-right-200	{ padding-right:200px !important; }

.padding-bottom-0	{ padding-bottom:0 !important; }
.padding-bottom-5	{ padding-bottom:5 !important; }
.padding-bottom-10	{ padding-bottom:10px !important; }
.padding-bottom-15	{ padding-bottom:15px !important; }
.padding-bottom-20	{ padding-bottom:20px !important; }
.padding-bottom-30	{ padding-bottom:30px !important; }
.padding-bottom-40	{ padding-bottom:40px !important; }
.padding-bottom-50	{ padding-bottom:50px !important; }
.padding-bottom-60	{ padding-bottom:60px !important; }
.padding-bottom-80	{ padding-bottom:80px !important; }
.padding-bottom-100	{ padding-bottom:100px !important; }
.padding-bottom-130	{ padding-bottom:130px !important; }
.padding-bottom-150	{ padding-bottom:150px !important; }
.padding-bottom-180	{ padding-bottom:180px !important; }
.padding-bottom-200	{ padding-bottom:200px !important; }

.padding-left-0	    { padding-left:0 !important; }
.padding-left-5	    { padding-left:5 !important; }
.padding-left-10	{ padding-left:10px !important; }
.padding-left-15	{ padding-left:15px !important; }
.padding-left-20	{ padding-left:20px !important; }
.padding-left-30	{ padding-left:30px !important; }
.padding-left-40	{ padding-left:40px !important; }
.padding-left-50	{ padding-left:50px !important; }
.padding-left-60	{ padding-left:60px !important; }
.padding-left-80	{ padding-left:80px !important; }
.padding-left-100	{ padding-left:100px !important; }
.padding-left-130	{ padding-left:130px !important; }
.padding-left-150	{ padding-left:150px !important; }
.padding-left-180	{ padding-left:180px !important; }
.padding-left-200	{ padding-left:200px !important; }

.margin-top-0		{ margin-top:0 !important; }
.margin-top-5		{ margin-top:5px !important; }
.margin-top-10		{ margin-top:10px !important; }
.margin-top-20		{ margin-top:20px !important; }
.margin-top-30		{ margin-top:30px !important; }
.margin-top-40		{ margin-top:40px !important; }
.margin-top-50		{ margin-top:50px !important; }
.margin-top-60		{ margin-top:60px !important; }
.margin-top-56    { margin-top:56px !important; }
.margin-top-80		{ margin-top:80px !important; }
.margin-top-100		{ margin-top:100px !important; }
.margin-top-130		{ margin-top:130px !important; }
.margin-top-150		{ margin-top:150px !important; }
.margin-top-180		{ margin-top:180px !important; }
.margin-top-200		{ margin-top:200px !important; }

.margin-left-0		{ margin-left:0 !important; }
.margin-left-5		{ margin-left:5px !important; }
.margin-left-10		{ margin-left:10px !important; }
.margin-left-15		{ margin-left:15px !important; }
.margin-left-20		{ margin-left:20px !important; }
.margin-left-30		{ margin-left:30px !important; }
.margin-left-40		{ margin-left:40px !important; }
.margin-left-50		{ margin-left:50px !important; }
.margin-left-60		{ margin-left:60px !important; }
.margin-left-80		{ margin-left:80px !important; }
.margin-left-100	{ margin-left:100px !important; }
.margin-left-130	{ margin-left:130px !important; }
.margin-left-150	{ margin-left:150px !important; }
.margin-left-180	{ margin-left:180px !important; }
.margin-left-200	{ margin-left:200px !important; }
.margin-left-250	{ margin-left:250px !important; }
.margin-left-300	{ margin-left:300px !important; }

.margin-bottom-0	{ margin-bottom:0 !important; }
.margin-bottom-5	{ margin-bottom:5px !important; }
.margin-bottom-10	{ margin-bottom:10px !important; }
.margin-bottom-20	{ margin-bottom:20px !important; }
.margin-bottom-30	{ margin-bottom:30px !important; }
.margin-bottom-40	{ margin-bottom:40px !important; }
.margin-bottom-50	{ margin-bottom:50px !important; }
.margin-bottom-60	{ margin-bottom:60px !important; }
.margin-bottom-80	{ margin-bottom:80px !important; }
.margin-bottom-100	{ margin-bottom:100px !important; }
.margin-bottom-130	{ margin-bottom:130px !important; }
.margin-bottom-150	{ margin-bottom:150px !important; }
.margin-bottom-180	{ margin-bottom:180px !important; }
.margin-bottom-200	{ margin-bottom:200px !important; }

.margin-right-0		{ margin-right:0 !important; }
.margin-right-5		{ margin-right:5px !important; }
.margin-right-10	{ margin-right:10px !important; }
.margin-right-15	{ margin-right:15px !important; }
.margin-right-20	{ margin-right:20px !important; }
.margin-right-30	{ margin-right:30px !important; }
.margin-right-40	{ margin-right:40px !important; }
.margin-right-50	{ margin-right:50px !important; }
.margin-right-60	{ margin-right:60px !important; }
.margin-right-80	{ margin-right:80px !important; }
.margin-right-100	{ margin-right:100px !important; }
.margin-right-130	{ margin-right:130px !important; }
.margin-right-150	{ margin-right:150px !important; }
.margin-right-180	{ margin-right:180px !important; }
.margin-right-200	{ margin-right:200px !important; }
.margin-right-250	{ margin-right:250px !important; }
.margin-right-300	{ margin-right:300px !important; }

.nopadding 			{ padding:0 !important; 		}
.nopadding-left 	{ padding-left:0 !important; 	}
.nopadding-right 	{ padding-right:0 !important; 	}
.nopadding-top 		{ padding-top:0 !important; 	}
.nopadding-bottom	{ padding-bottom:0 !important; 	}
.nomargin 			{ margin:0 !important; 			}
.nomargin-left 		{ margin-left:0 !important; 	}
.nomargin-right 	{ margin-right:0 !important; 	}
.nomargin-top		{ margin-top:0 !important; 	}
.nomargin-bottom	{ margin-bottom:0 !important; 	}
.noborder 			{ border:0 !important; 			}
.noborder-left		{ border-left:0 !important; 	}
.noborder-right		{ border-right:0 !important; 	}
.noborder-top		{ border-top:0 !important; 		}
.noborder-bottom	{ border-bottom:0 !important; 	}
.nobg				{ background:transparent; 		}
.noradius			{ -webkit-border-radius:0 !important; -moz-border-radius:0 !important; border-radius:0 !important; }
.pointer 			{ cursor:pointer; 				}
.block 				{ display:block !important; 	}
.block-inline		{ display:inline-block !important; 	}
.inline-block		{ display:inline-block !important; 	}
.fullwidth 			{ width:100% !important; max-width:100% !important; 		}
.halfwidth 			{ width:50% !important; 		}
.relative 			{ position:relative;			}
.absolute			{ position:absolute !important; top:0; left:0; right:0; bottom:0;	}
.noshadow			{ box-shadow:none; text-shadow:none; }
.nofloat			{ float:none !important; 		}


.maxw-1280{
  max-width: 1420px;
}
.white-font{
  color: #fff;
}
