//MIXINS//
@import "mixin.scss";
//VARIABLES//
@import "variables.scss";

.navbar{
  top:0;
}
.page__top{
  background-color: #f6f6f6;
  display: block;
  position: relative;
  z-index: 1;
  @include transition(ease 550ms);
}
.page__top-transparent{
  background-color:rgba(0,0,0, .7);

}
.ls__logo{
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;

  .ls__logo-link{
    line-height: 0;
    margin: 0 auto;
    display: block;
    width: 280px;
    @include transition(ease 550ms);
    @include phone{
    width: 200px;
    }
    @media screen and(max-width: 1024px){
      width: 200px;
      line-height: 65px;
    }
  }

}

#Layer_1{
  @include transition(ease 550ms);
}

//Nav change size on scroll///
.ls__logo-link--small{
  width: 180px!important;
}
.signing-small{
  line-height: 54px!important;
  color: #fff;
  a{
    color: #fff;
  }

}
.ls__logo-white{
display: none!important;
}
.ls__logo-black-none{
  display: none!important;
}
.ls__logo-white-block{
  display: block!important;
}

.signing{
  line-height: 76px;
  float: right;
    @include transition(ease 550ms);
  span{
    margin: 0 15px 0 15px;
  }
  a{
    padding: 20px 0 20px 0;
  }
}
.login-link, .register-link{
  font-weight: 300;
}
.navbar{
  background-color: rgba(255,255,255, .0);
  position: fixed;
  margin: 0;
  width: 100%;
  z-index: 100;
  border-radius: 0!important;
  border: none;
}
.page__nav{
  background-color: #fff;
  @include box-shadow(0 0 5px rgba(0, 0, 0, 0.2));

}
.nav__shop{
  max-width:95px;
  text-align: center;
  border-bottom: 2px solid $gold-dark;
  padding: 15px 0 10px 0;
  font-size: 15px;
}
.nav__subnav{
  text-align: center;
  .nav__mobile{
    text-align: left;
    margin: 0;
    width: 100%;
    line-height: 3;
    display: block;
  }
 li{
   margin-right: 30px;
   display: inline;
   &:last-child{
     margin-right: 0;
   }
 }
}
.nav__center{
  margin-top: 15px;
.nav__subnav-link{
  font-size: 15px;
  font-weight: 300;
}
}
.nav__cart{
  position: relative;
  margin-top: 12px;
  float: right;
  font-size: 26px;
}
.cart__item-value{
  position: absolute;
  right: -10px;
  top:-3px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  background: $gold-dark;
  color: #fff;
  width: 17px;
  height: 17px;

}



//shopping cart
/* --------------------------------

Primary style

-------------------------------- */
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}



/* --------------------------------

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
  /* this class is used to horizontally align the gallery of items */
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.cd-img-replace {
  /* replace text with a background-image */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;

}
.cd-cart-image{
  line-height: 55px;
  font-size: 25px;
  padding: 0px 40px;
  @media screen and (max-width: 767px){
    line-height: 40px;
  }
  &:hover{
    i{color: $gold;}
  }
}

/* --------------------------------

xheader

-------------------------------- */
header {
  min-height: 55px;
  position: relative;
  width: 100%;
  background: #fff;
  text-align: center;
  z-index: 4;
  @include phone{
    min-height: 57px;
  }
  @media screen and(max-width: 767px){
    min-height: 40px;
  }
  @include tablet{
    min-height: auto;
  }
}



#cd-hamburger-menu{
  position: relative;
  float: left;
  height: 39px!important;
  a{
      line-height: 39px!important;
  }

}

.cd-cart-trigger {
  position: relative;
  float: right;
  top: 0;
  height: 40px;
}
#cd-hamburger-menu a, .cd-cart-trigger a {
  width: 60px;
  height: 100%;
}
#cart-close{
  font-size: 14px;
  color: #999;
      padding: 0;
  &:hover{
    color: $gold;

  }
}

#cd-hamburger-menu {
  left: 0;
}
#cd-hamburger-menu a {
  background: #fff url("../assets/images/cd-hamburger-menu.svg") no-repeat center center;
}
@media only screen and (min-width: 768px) {
  #cd-hamburger-menu {
    display: none;
  }
}

.cd-cart-trigger {
  right: 0;
}
.cd-cart-trigger a {
  color: #000;

}
@media only screen and (min-width: 1200px) {
  .cd-cart-trigger {
    /* cart right margin on desktop */
    right: 0;
  }
  .cd-cart-trigger a {
    position: relative;
    width: 100px;
    border-left: none;
    background-color: #fff;
  }
}

#cd-cart {
  top: 0;
  height: 120%;
  overflow-y: auto;
  /* header height */
  overflow-scrolling: touch;
  z-index: 1000!important;
  position: fixed;
  padding-top: 0!important;
  @include box-shadow((0,0,10px #000!important));

}

#main-nav{
  @media screen and (max-width: 767px) {
    position: fixed;
    top: 0;
    height: 100%;
    width: 80%;
    max-width: 500px;
    overflow-y: auto;
    /* header height */
    overflow-scrolling: touch;
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0.2));
    z-index: 100;
  }
  @include phone{

  }
}

.main__nav-link{

  color: #444;
  border-bottom: 2px solid #fff;
  &:hover{
    color: $gold-dark!important;
    border-bottom: 2px solid $gold!important;
  }
  @media only screen and (max-width: 767px){
    font-size: 15px!important;
    font-weight: 500!important;
    margin-top: 10px!important;
  }
}

@media only screen and (min-width: 768px) {
  #main-nav, #cd-cart {
    width: 320px;
  }
}
@media only screen and (min-width: 1200px) {
  #main-nav, #cd-cart {
    width: 30%;
    /* header height has changed */
  }
}

#main-nav {
  left: -100%;
  background: #FFF;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  transition: left 0.3s;
}
#main-nav.speed-in {
  left: 0;
}
#main-nav ul a {
  display: block;
  line-height: 50px;
  padding: 0 1em;
  font-size: rem(14px);
  font-weight: 300;

}

@media only screen and (min-width: 768px) {
  #main-nav {
    /* reset main nav style */
    width: 100%!important;
    position: static;
    width: auto;
    top: 0;
    /* header height */
    line-height: 55px;
    z-index: 4;
    font-size: 15px;

  }
  #main-nav li {
    display: inline-block;
  }
  #main-nav ul a {
    width: 100%;
    display: inline-block;
    line-height: 1;
    padding: 18px 1.4em;
    color: #000;
  }
  .no-touch #main-nav ul a:hover {
    color: $gold-dark;
  }
}
.main__nav{
  width: 72%;
  margin: auto;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 80%;
    max-width: 200px;
    margin-top: 230px;

  }
}
.main__nav-shop--box{
  display: inline-block;
  float: left;
  margin-left: 50px;
  line-height: 55px;
  @media screen and (max-width: 767px) {
    border-top: 1px solid $gold;
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
.main__nav-shop--link{
  padding: 13px 20px 13px 30px;
  border-bottom: 2px solid $gold!important;
  i{
    margin-left: 5px;
    display: inline;
  }
}
.dropdown__list-trigger{
  padding-top: 6px!important;
}

.no-js #main-nav {
  position: fixed;
}

#cd-cart {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  /* header height */
  padding-top: 130px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: right 0.3s;
  background: #FFF;
  transition: right 0.3s;
  -webkit-transition: right 0.3s;
  -moz-transition: right 0.3s;
  transition: right 0.3s;
  border-top: 1px solid $gold;
  border-bottom: 1px solid $gold;
  .cd-cart-trigger{
    top:-52px;
    @media screen and (max-width: 767px) {
    top:-45px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 500px;
  }
  @include phone {
    width: 80%;
    padding-top: 113px;
  }
  @include tablet{
    width: 100%;
    max-width: 500px;
  }
}

#cd-cart.speed-in {
  right: 0;
}
#cd-cart > * {
  padding: 0 1em;
}
#cd-cart h2 {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1em 0;
}
#cd-cart .cd-cart-items {
  font-size: 1em;
  padding: 0;
  @media screen and (max-width: 768px) {
      font-size: .9em;
  }
  @include phone{
    font-size: .8em;
  }
}
#cd-cart .cd-cart-items li {
  position: relative;
  padding: 1em;
  border-top: 1px solid #f2e7d8;
  &:first-child{
    border-top:none;
  }
}
#cd-cart .cd-cart-items li:last-child,  {
  border-bottom: 1px solid #e0e6ef;
}
.sub__cart-product--image{
  line-height: 0;
  margin-right: 10px;
  float: left;
  width: 62px;
  height: 62px;
  border: 1px solid #f1f1f1;
  text-align: center;
  img{
    margin-top: 10%;
    width: 50%;
  }
}

.product--qty, .cart-label, {
  color: #a5aebc;
}

.cd-shipping{
  margin-right: 35px;
  float: right;
  display: block;
  color: $gold;
  @media screen and (max-width: 768px) {
      float:none;
  }
}
#cd-cart .cd-item-remove {
  position: absolute;
  right: 1em;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background: url("../assets/images/cd-remove-item.svg") no-repeat center center;
}
.no-touch #cd-cart .cd-item-remove:hover {
  background-color: #e0e6ef;
}

.cd-cart-total{
  margin-top: 20px;
  margin-bottom: 20px;
}
#cd-cart .cd-total {
  float: right;
}
#cd-cart .cd-cart-total::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}
#cd-cart .checkout-btn {
  margin: auto;
  display: block;
  width: 60%;
  height: 40px;
  line-height: 40px;
  background: $blue;
  color: #FFF;
  text-align: center;
  @include box-shadow(0px 7px 8px 0px rgba(17, 80, 193, 0.64));
  @include transition(ease 150ms);
  &:hover{
    opacity: .7;
      @include box-shadow(0px 4px 5px 0px rgba(17, 80, 193, 0.64));
  }
}
.no-touch #cd-cart .checkout-btn:hover {
  background: #a2dda8;
}
#cd-cart .cd-go-to-cart {
  text-align: center;
  margin: 1em 0;
}
#cd-cart .cd-go-to-cart a {
  text-decoration: underline;
}
@media only screen and (min-width: 1200px) {
  #cd-cart > * {
    padding: 0 2em;
  }
  #cd-cart .cd-cart-items li {
    padding: 1em 2em;
  }
  #cd-cart .cd-item-remove {
    right: 2em;
  }
}

/* --------------------------------

xgallery

-------------------------------- */
main {
  position: relative;
  min-height: 100%;
  background: #eeeff7;
  padding-top: 70px;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  main {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 1200px) {
  main {
    padding-top: 120px;
  }
}

#cd-gallery-items li {
  margin-bottom: 1.5em;
}
#cd-gallery-items li img {
  width: 100%;
  display: block;

}
@media only screen and (min-width: 768px) {
  #cd-gallery-items li {
    width: 48%;
    float: left;
    margin-bottom: 2em;
    margin-right: 4%;
  }
  #cd-gallery-items li:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1200px) {
  #cd-gallery-items li {
    width: 31%;
    float: left;
    margin-bottom: 2.5em;
    margin-right: 3.5%;
  }
  #cd-gallery-items li:nth-child(2n) {
    margin-right: 3.5%;
  }
  #cd-gallery-items li:nth-child(3n) {
    margin-right: 0;
  }
}
.overflow-hidden{
  overflow: hidden;
}
#cd-shadow-layer {
  position: fixed;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  z-index: 100;
  display: none;
}
#cd-shadow-layer.is-visible {
  display: block;
  -webkit-animation: cd-fade-in 0.3s;
  -moz-animation: cd-fade-in 0.3s;
  animation: cd-fade-in 0.3s;
}

/* --------------------------------

xkeyframes

-------------------------------- */
@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


//dropdown shop
.sub__menu-mobile{
  text-align: left;
  background: #f7f7f7;
  @include transition(ease 150ms);
  display: none;
  height: 0;
  @media screen and (max-width: 767px) {
    display: block;
    height: 150px;
  }
}

.sub__menu-mobile--item a img{
  width: 22px;
  margin-left: -25;
  margin-right: 25px;
}


#sub__menu{
  background-color: #fff;
  display: none;
  opacity: 0;
  @include transition(ease 350ms);
  border-bottom: 1px solid $gold;
  min-height: 240px;
  position: absolute;
  @include box-shadow(2px 2px 20px rgba(0,0,0,0.1));
  line-height: 0;
  z-index: 0;
  max-width: 500px;
  min-width: 320px;
  @media screen and (max-width: 767px) {
      display: none!important;
  }

  .sub__menu-item{
    margin-top: 10px;
    margin-bottom: 5px;

    &:hover{
      .sub__menu-link img, span{
        opacity: 0.6;
        transform: scale(1.05);
      }

    }
  }
}
.sub__menu-link{
  img{
    @include transition(ease 200ms);
    padding-top: 15px;
    width: 100%;
  }
}

.dropdown__list{
  &:hover{
    #sub__menu{
      opacity: 1;
      display: block;

    }
  }
}

.sub__menu-title{
  text-align: center;
  font-family: 'libre baskerville',arial,sans-serif;
  font-size: 16px;
  font-style: italic;
  letter-spacing: -1px;
  color: $gold;
  margin: 0;
  line-height: 50px;
  border-bottom: 1px solid #f2e7d8;
  border-top: 1px solid $gold  ;
}
