//MIXINS//
@import "mixin.scss";
//VARIABLES//
@import "variables.scss";

.user__info{
  margin-top: 200px;
}

.main__form{
  text-align: center;
}
.form__field{
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}
.input-label{
  display: inline;
  position: absolute;
  background: #fff;
  margin-left: 10px;
  top: -14px;
  padding: 5px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: rem(12px);
}
.input-style{
  max-width: 300px;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  padding-top: 12px;
  border: 1px solid #999!important;
  &:focus, &:active{
    border: 1px solid $gold!important;
    outline: none;
  }
  &:hover{
  border: 1px solid $gold!important;
  }
}

.btn-next{
  margin: 0 auto;
  letter-spacing: 1px;
  width: 100%;
  max-width: 300px;
  display: block;
  color: #fff;
  height: 40px;
  line-height: 1;
  padding-top: 12px;
  &:hover,&:focus, &:active{
    color: #fff;
  }
}
