//MIXIN
@import "mixin";
//VARS
@import "variables";
//HEADER//
@import "header.scss";
//CAROUSEL//
@import "carousel";
//SHOP SLIDER
@import "product__shop-slider";
@import "footer";

//Section____POLO_LEGENG//
.polo__legend{
  padding: rem(90px) 0 rem(100px) 0;
  background: url('../assets/images/polo-legend.jpg') no-repeat center center;
  background-size: cover;
  @media screen and (max-width: 768px) {
  @include linear-gradient(linear-gradient(100deg, #292421 0%,#565043 100%));
  }
}

.polo__legend-item{
  height: 200px;
}
.polo__legend-box{
  padding: 0 5px 0 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
  text-align: center;
  @media screen and (max-width: 768px) {
    max-width: 320px;
    margin: 0 auto 40px auto;
  }
  img{
    width: 62px;
    margin-bottom: 10px;
  }
  h3{
    font-size: rem(24px);
    font-family: 'Playfair Display',sans-serif,arial;
    text-transform: uppercase;
    margin-bottom: 15px;
    @media screen and (max-width: 768px) {
      font-size: rem(16px);
    }
  }
  p{
    font-size: rem(14px);
    font-weight: 300;
    line-height: 1.41;
  }
  strong{
    display: block;
  }
}

//LegendStick info section
.ls__info{
  background: url('../assets/images/texture.jpg')
}
.ls__info-logo{
  text-align: center;
  img{
    max-width: 210px;
    width: rem(210px);
  }

}

.ls__info-text{
  max-width: 540px;
  margin: 0 auto;
  line-height: 1.41;
  padding: 0 10px;
  font-size: rem(15px);
  font-weight: 400;
}
.ls__info-bg{
  background: url('../assets/images/legend-shop.jpg') no-repeat;
  background-size: cover;

}

.ls__info-subbox{
  padding-left: 30px;
  padding-right: 30px;
  min-height: 315px;
  @include transition(linear 150ms);
  cursor: pointer;
  p{
    font-size: rem(15px);
  }
  &:hover{
    opacity: .7;
  }
}
.ls__info-perfectfit{
  background: url('../assets/images/tailor.jpg') no-repeat;
  background-size: cover;

}
.ls__info-6-months{
  background: url('../assets/images/elegant-man.jpg') no-repeat;
  background-size: cover;
}
