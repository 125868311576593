@import "variables";
@import "mixin";

.product__shop-slider{
  padding-top: rem(50px);
  padding-bottom: rem(20px);
  border-bottom: 1px solid $gold;
  border-top: 1px solid $gold;
  height: 600px;
  @media screen and(max-width: 768px){
    height: rem(600px);
  }
  @include phone{
    height: rem(650px);
  }
}


.slider__arrow{
  margin-top: 100px;
  background: rgba(0, 0, 0, .7);
  padding: 14px 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 10000;
  a{
    height: 100%;
  }
  @include phone{
    margin-top: 100px;
  }

}


.hover-item, .ls__shop-item{
  @include transition(ease 350ms);
  &:hover{
    .small-btn{
      opacity: 1;

    }
    .shop-tooltip{
      opacity: 1;
      display: block;
    }
  }
}

.small-btn{
  margin-top: 20px;
  @include transition(ease 150ms);
  display: block;
  opacity: 0;
  line-height: 30px;
  color: #fff;
  margin: 0 auto;
  height: 30px;
  width: 120px;
  &:hover{
    color: #fff;
  }
  @media screen and(max-width: 960px){
    opacity: 1;
    margin-top: 15px;
  }
}

.carousel-pic{
  margin: 0 auto;
  max-width: 150px;
  display: block;
  position: relative;
  max-height: 260px;

}
.textile{
  overflow: hidden;
  position: absolute;
  height: rem(70px);
  width: rem(70px);
  border-radius: 50%;
  border: 2px solid #FFF;
  @include box-shadow(0px 4px 5px 0px rgba(0, 0, 0, 0.2));
  bottom:0;
  right: -22px;
}
.badge {
  background: $gold-dark;
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 31px;
  font-family: 'catamaran', sans-serif;
  font-weight: 300;
  font-size: 14px;
  border: 2px solid #FFF;
  box-shadow: 0 0 0 1px $gold;
  top: 14px;
  right: 8%;
}
#slider-control img{
  margin: 0 auto;
}



.carousel__product-link{
  margin: 0 auto;
  @include transition(ease 150ms);
  display: block;
  &:hover{
    opacity: .9;
    color: #000;
    .shop-tooltip{
      opacity: 1;
    }
  }
}
.shop-tooltip{
  padding: 11px 10px 10px 10px;
  color: #fff;
  @include transition(ease 350ms);
  position: absolute;
  top: 60px;
  width: 70%;
  right: 0;
  left: 0;
  text-align: center;
  opacity: 0;
  margin: 0 auto;
  height: 60px;
  z-index: 1000;
  border-radius: 3px;
  font-size: rem(14px);
  background: rgba(0, 0, 0, .7);
  &:before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.69);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: auto;
    bottom: -12px;
    transform: rotate(-90deg);
  }
}

.carousel-product-h4{
  margin-top: 18px;
  text-align: center;
  padding: 0 rem(40px);
  line-height: 18px;
  letter-spacing: 1px;
  font-size: rem(14px);
  font-size: 300;
  color:#252525;
}
.carousel-product-price{
  margin-top: 5px;
  display: block;
  text-align: center;
  font-size: 14px;
  color: #999;
  font-weight: 600;
  font-family:'baskerville',arial, sans-serif;
}



.carousel-pic{
  min-height: 250px;
  max-height:250px;
  @include phone{
    max-width: 110px;
    min-height: 180px;
    max-height: 180px;
  }
}
.carousel__pic-product--image{
  width: 100%;
}


.swiper-container {
    width: 100%;
    height: auto;
    margin: 150px auto;
    max-width: 1140px;
    overflow: hidden;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 19%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 0px;

    @include tablet{
      width: 33.333%;
    }
    @include laptop{
      width: 20%;
    }
    @media screen and(max-width: 768px){
      width: 50%;
    }
    @include phone{
      width: 50%;
    }
}
