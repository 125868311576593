//INDEX-PAGE//
@import "index.scss";
//SHOP//
@import "shop.scss";
//User__INFO//
@import "user__info-flow";

//Inner Pages styles
@import "inner__pages-style";

//ADJUSTMENT//
@import "adjustment.scss";
//UTILITIES//
@import "utilities";






.blue__button-style{
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  font-family: 'catamaran',arial,sans-serif;
  background: $blue;
  line-height: 50px;
  height: 50px;
  width: 250px;
  text-align: center;
  border: none;
  @include box-shadow(0px 7px 8px 0px rgba(17, 80, 193, 0.64));
  @include transition(ease 150ms);
  &:hover{
    background: #3e82ff;
      @include box-shadow(0px 4px 5px 0px rgba(17, 80, 193, 0.64));
  }
  a{
    display: block;
    height: 100%;
    width: 100%;
    color: #fff;
  }
}
.polo__legend-title{
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  margin-bottom: rem(112px);
  @include phone{
    margin-bottom: rem(80px);
  }
}
.ls__title-h2{
  font-size: rem(44px);
  font-family: 'Playfair Display',sans-serif,arial;
  text-transform: uppercase;
  text-align: center;
  padding: 0 15px 0 15px;
  @include phone{
    font-size: rem(32px);
    margin-bottom: rem(80px);
  }
}
.ls__title-h3{
  font-size: rem(24px);
  font-family: 'Playfair Display',sans-serif,arial;
  line-height: 1.41;
  strong{
    display: block;
    text-transform: uppercase;
  }
}
.ls__paragraph{
  font-size: rem(14px);
  font-weight: 300;
  color: #666666;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: #fff!important;
}
