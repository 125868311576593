//MIXINS//
@import "mixin.scss";
//VARIABLES//
@import "variables.scss";




.shop__catalogue{
  padding: 90px 0 110px 0;
  background: url('../assets/images/texture.jpg');
}
.shop__box{
  padding-top: 315px;
  position: relative;
  margin: 0 auto;
  max-width: 480px;
  background-size: cover;
  background-color: #000;
  @include box-shadow(0px 5px 51px 0px rgba(0, 0, 0, 0.1));

  @media screen and (max-width: 768px) {
    padding-top: 140px;
    margin-bottom: 70px;
    height: 300px;
    width: 98%;
    max-width: 420px;
  }
  @include tablet{
    padding-top: 115px;
    height: 268px;

  }
  @include laptop{
    padding-top: 115px;
    max-width: 280px;
    height: 250px;
  }
  @include desktop{
    padding-top: 215px;
    max-width: 480px;
    max-height: 480px;
    height: 408px;

  }
}

.shop-1{
  background: url('../assets/images/shop-1.jpg')  no-repeat center 21% ;
}
.shop-2{
  background: url('../assets/images/shop-2.jpg')  no-repeat center 21% ;
}
.shop-3{
  background: url('../assets/images/shop-3.jpg')  no-repeat center 21% ;
}

.shop__text{

  text-align: center;
}
.shop__btn{
  left: 0;
  right:0;
  margin: 0 auto;
  position: absolute;
  bottom:-25px;
  width: 80%;
  max-width: 240px;
  text-shadow: none;
}

.shop-icon{
  position: relative;
  left:10px;
}


.shop__text{
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  display: block;
  padding: 0 5px 0 5px;

}
.shop__text-label{
  font-size: rem(18px);
  font-weight: 300;
  @include tablet{
        font-size: rem(14px);
  }
  @include laptop{
      font-size: rem(16px);
  }
}
.shop__text-title{
  font-size: rem(36px);
  font-family: 'Playfair Display',sans-serif,arial;
  font-style: italic;
  letter-spacing: -2px;
  margin-bottom: 5px;
  @include tablet{
        font-size: rem(28px);
  }
  @include laptop{
      font-size: rem(35px);
  }
  @include desktop{
      font-size: rem(32px);
  }
}
