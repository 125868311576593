@import 'mixin';
@import 'variables';


.ls__page-bottom--contact{
  background: #e6e6e6;
}
.ls__page-bottom--container{
  max-width: 520px;
  margin: 0 auto;
  min-height: 82px;
  @include phone{
    min-height: 164px;
  }
}
.ls__contacts{
  background: #e6e6e6;
  line-height: 82px;
  text-align: center;
  float: left;
  width: 50%;
  display: block;
  img{
    margin-right: 10px;
  }
  @include phone{
    width: 100%;
  }
}

.ls__page-bottom{
  background: #2b2b2b;
}

.ls__page-bottom--socials{
  @include phone{
    text-align: center;
  }
  li{
    display: inline;
    margin-right: 10px;
    cursor:pointer;
  }
}
.social-link {
  text-align: center;
  line-height: 31px;
  background: #fff;
  border-radius: 30px;
  display: inline;
  cursor:pointer;
  @include transition(ease 150ms);
  &:hover{
  opacity: .6;
  }
  img{
      width: 31px;
  }
}

#footer__links{
  margin-bottom: 50px;
  li{
    margin-bottom: 15px;
    a{
      color: #fff;
      &:hover{
        color: $gold-dark;
      }
    }
    @include phone{
      text-align: center;
    }
  }
}
#footer__links-bottom{
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  line-height: 24px;
  li{
    display: inline-block;
    margin-right: 5%;
    a{
      font-size: rem(14px);
      font-weight: 500;
      letter-spacing: 1px;
      color: #fff;
      &:hover{
        color: $gold-dark;
      }
    }
  }
}
#footer-logo{
  display: block;
  width: 100%;
  max-width: 200px;
  @include phone{
    margin: 0 auto;
  }
}
#newsletter-title{
  font-weight: bold;
  color: #fff;
  font-size: rem(14px);
  margin-bottom: 10px;
}
#newsletter-text{
  color: #fff;
  opacity: .4;
  font-size: 13px;
  line-height: 1.41;
  margin-top: 10px;
  font-weight: 400;
}
#footer__email-input{
  display: inline;
  width: 80%;
  height: 38px;
  padding-left: 10px;
  border: 1px solid white;
  background: rgba(255,255,255, .2);
  @include transition(ease 250ms);
  &:hover{
    background: rgba(255,255,255, .3);
  }
}
#footer__email-btn{
  float: right;
  display: inline;
  margin-left: 1%;
  width:18% ;
  height: 38px;
  border-radius: 0;
  background: $blue;
  color: #fff;
  @include transition(ease 250ms);
  &:hover{
    background: #3e82ff;
  }
}

#footer-signing{
  float: right;
  margin: 30px 0;
  @include phone{
    float: none;
    text-align: center;
  }
  li{
    text-align: right;
    margin-bottom: 10px;
    a{
      color: #fff;
      &:hover{
        color: $gold-dark;
      }
    }
    @include phone{
      text-align: center;
    }
  }
}

#copyright{
  color: #707070;
  line-height: 40px;
  text-align: center;
  background: #202020;
}
